.colRunning {
    border: '1px solid red';
    margin: '5px';
    padding: '5px';
    border-radius: '10px';
    box-shadow: '5px 5px 30px black';
   }
   .colRunning:hover {
    /* background: #7af571; */
    /*background: linear-gradient(90deg, rgba(76,222,31,1) 0%, rgba(9,121,11,0.989233193277311) 50%);*/
    transform: scale(1.02);
    box-shadow: 20px 20px 50px black;
   }
   .colStopped {
    border: '1px solid red';
    margin: '5px';
    padding: '5px';
    border-radius: '10px';
    box-shadow: '5px 5px 30px black';
   }
   .colStopped:hover {
    /* background: #95938c; */
    /*background: linear-gradient(90deg, rgba(76,222,31,1) 0%, rgba(9,121,11,0.989233193277311) 50%);*/
    transform: scale(1.02);
    box-shadow: 20px 20px 50px black;
   }
   .colDamaged {
    border: '1px solid red';
    margin: '5px';
    padding: '5px';
    border-radius: '10px';
    box-shadow: '5px 5px 30px black';
   }
   .colDamaged:hover {
    /* background: #f83701; */
    /*background: linear-gradient(90deg, rgba(76,222,31,1) 0%, rgba(9,121,11,0.989233193277311) 50%);*/
    transform: scale(1.02);
    box-shadow: 20px 20px 50px black;
   }
   .colIdle {
    /* background: #f0bc5e;*/
    border: '1px solid red';
    margin: '5px';
    padding: '5px';
    border-radius: '10px';
    box-shadow: '5px 5px 30px black';
   }
   .colIdle:hover {
    /* background: #f0bc5e; */
    /*background: linear-gradient(90deg, rgba(76,222,31,1) 0%, rgba(9,121,11,0.989233193277311) 50%);*/
    transform: scale(1.02);
    box-shadow: 20px 20px 50px black;
   }
   .running {
    background-image: url(./Landing\ page-metro\ image.png);
    background-size: 100%;
    padding-top: 92%;
    height: 0;
    background-repeat: no-repeat;
   }
   .idle {
    background-image: url(./Landing\ page-metro\ image.png);
    background-size: 100%;
    padding-top: 92%;
    height: 0;
    background-repeat: no-repeat;
   }
   .stop123 {
    /* background-image: url(./Landing\ page-metro\ image.png); */
    background-size: 100%;
    padding-top: 92%;
    height: 0;
    background-repeat: no-repeat;
   }
   .rectangle {
    height: 50px;
    width: 100px;
    background-color: #555;
  }
   .damaged {
    background-image: url(./Landing\ page-metro\ image.png);
    background-size: 100%;
    padding-top: 100%;
    height: 0;
    background-repeat: no-repeat;
   }
   .titleStyling {
    text-align: center;
   }
   .gutter-box {
    background-color: #8c8c8c;
    color: #e8e8e8;
    border: 2px solid #e8e8e8;
    text-align: center;
    font-size: 2em;
    height: 100px;
    margin: 5px;
    box-shadow: 5px 5px #bfbfbf;
   }
   .gutterTitle {
    color: #e8e8e8;
    text-align: center;
   }
   .tphhead {
    color: black;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
   
    border-bottom: 1px dotted black;
    margin-bottom: 10px;
   }
   .tphBox {
    border: 1px dotted black;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
   }
   .ant-typography {
    text-align: center;
    color: white;
   }
   .defaultCover{
       background-image: url(http://www.rubusdigital.com/wp-content/uploads/2020/01/home-banner.jpg);
       background-position: center center;
       background-repeat: no-repeat;
       background-size: cover;
       height:960px;
   
   }
   .ant-badge-status-dot {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 20px;
    height: 10px;
    vertical-align: middle;
  }