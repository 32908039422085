.trendsFields {
  font-weight: bold;
  margin-top: 6px;
  font-size: 16px
}

.trendTable tr {
  background-color: #011931 !important;
  color: black !important;
  text-align: center !important;
}

.trendTable th {
  color: white !important;
  font-weight: bold !important;
  text-align: center !important;
}

.trendTable th {
  background-color: #214972 !important;
  font-weight: bold !important;
}

.trendTable tbody tr:hover td {
  background-color: #dedede !important;
  cursor: pointer;
  color: black !important;
}

.trendTable tr:nth-child(even) {
  background: #7a8793 !important;
}

.trendTable tr:nth-child(odd) {
  background: #bdc0c2 !important;
}

.radioGroup .ant-radio-wrapper {
  color: white;
  font-size: 16px;
}

.radioGroup .ant-radio {
  margin: 5px;
}
.ant-progress-text {
  color: hwb(0 60% 37%) !important;
}

.progressbarcircle {
  width: auto !important;
  height: 80% !important;
  display: flex;
  justify-content: center;
  align-items: center;

}

.progressbarline {
  width: auto !important;
  height: 80% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.ant-progress.ant-progress-line {
  width: 80% !important;

  /* margin: 15%  !important; */
}

.ant-progress.ant-progress-circle {
  margin-top: 5px;
  width: 40% !important;

  display: flex;
  justify-content: center;
  align-items: center;

  /* margin: 15%  !important; */
}

div[style].ant-progress-inner {
  width: 80% !important;
  height: auto !important;
}

/*  */
.ant-calendar-picker-input.ant-input {
  color: rgba(0, 0, 0, 0.9) !important;
}

.ant-calendar-range-picker-separator {
  color: rgba(0, 0, 0, 0.9) !important;
}

.ant-radio-group {
  color: rgba(0, 0, 0, 0.9) !important;
}

.ant-select-selection--single .ant-select-selection__rendered {
  color: rgba(0, 0, 0, 0.9) !important;
}

.warning-placeholder {
  position: relative;
  z-index: 1;
  margin-top: -1px;
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
  background: transparent;
  border-radius: 0 0 4px 4px;
}

/* .trendsFormLabel .ant-form-item-label>label {
} */