.rdrDateRangePickerWrapper.PreviewArea {
  z-index: 1;
  position: absolute;
  margin-left: -337px;
}

.datebutton {
  background-color: #121212;
  border: none;
  color: white;
  height: 38px;
  margin-right: 12px;
  font-family: "Roboto-Medium";
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../../CSS/Fonts/Roboto/Roboto-Medium.ttf");
}

.drawerToggle {
  
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 52px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  pointer-events: auto;
  position: fixed;
}


.site-drawer-render-in-current-wrapper {
  position: relative;
  overflow: hidden;
}

.drawerBlackTheme .ant-drawer-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #0e1e2d;
  pointer-events: auto;
  color: white;
}

.drawerBlackTheme .ant-drawer-title {
  flex: 1 1;
  margin: 0;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.treeBlackTheme .ant-tree-list-holder-inner {
  align-items: flex-start;
  background: #0e1e2d;
  color: white;
}

.treeBlackTheme .ant-tree-show-line .ant-tree-switcher {
  background: #0e1e2d;
}

.treeBlackTheme .ant-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
  background: #0e1e2d;
}

.treeBlackTheme.ant-tree-show-line .ant-tree-switcher {
  background: #334251;
}

.treeBlackTheme.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #6f7377;
}
.treeBlackTheme.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #6f7377;
}