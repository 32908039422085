.configDiv {
    background-color: #f0f0f0;
    border: 1px solid #595959;
    border-radius: 10px;
    margin-top: 20px;
    width: 100%;
    padding-top: 15px;

}

.configButton {
    float: right;
    margin: 0px 20px 20px;
}


.column-div {
    padding-left: 80px;
    padding-right: 80px;
}

.form-control {

    border: 0.5px solid #d9d9d9;
    margin-left: 30px;
    height: 30px;
    /* padding-right: 50px; */
}

.btn-link {
    height: 20px;
    margin-left: 20px;
}

th.table-column {
    background-color: rgb(170, 163, 163) !important;
    text-align: center !important;
    font-weight: 900;

}

tr.table-column {
    text-align: center !important;
}