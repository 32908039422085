@font-face { font-family: "Digital-Plain"; src: url('./Fonts/Analog/digital-7.ttf'); }

@font-face { font-family: "Roboto-Regular"; src: url('./Fonts/Roboto/Roboto-Regular.ttf'); }

.ModalTable .ant-table-selection-column .ant-table-header-column{
    display: none !important;
}
.sldModalTable .ant-table-selection-column .ant-table-header-column{
    display: none !important;
}
.sldModalTable tr > th {
    text-align: center !important;
  font-weight: bold;
  font-size: 17px !important;
    color: #fff !important;
  }
  
  .sldModalTable tr:nth-child(odd) {
    background: #efefef !important;
  }
  .sldModalTable tr:nth-child(even) {
    background: #d9d9d9 !important;
  }
  
  .sldModalTable .ant-table-tbody > tr > td {
    text-align: center !important;
    padding: 16px 0px !important;
    font-weight: bold;
  font-size: 15px !important;
  }

  .sldModalTable th.table-column {
    background-color: #292961 !important;
    color: #fff !important;
  }
  .sldModalTable tr > th {
    background-color: #292961 !important;
    color: #fff !important;
  }

  
 /*themeing starts */

.theme1 th.table-column {
    background-color: #292961 !important;
    color: #fff !important;
  }
  .theme1 tr > th {
    background-color: #292961 !important;
    color: #fff !important;
  }
  .theme2 th.table-column {
    background-color: #4b4ba3 !important;
    color: #fff !important;
  }
  .theme2 tr > th {
    background-color: #4b4ba3 !important;
    color: #fff !important;
  }
  .theme3 th.table-column {
    background-color: #1a3652 !important;
    color: #fff !important;
  }
  .theme3 tr > th {
    background-color: #1a3652 !important;
    color: #fff !important;
  }
  .theme4 th.table-column {
    background-color: #2261a1 !important;
    color: #fff !important;
  }
  
  .theme4 tr > th {
    background-color: #2261a1 !important;
    color: #fff !important;
  }
  .theme5 th.table-column {
    background-color: #0d4524 !important;
    color: #fff !important;
  }
  .theme5 tr > th {
    background-color: #0d4524 !important;
    color: #fff !important;
  }
  .theme6 th.table-column {
    background-color: #156b39 !important;
    color: #fff !important;
  }
  .theme6 tr > th {
    background-color: #156b39 !important;
    color: #fff !important;
  }
  .theme7 th.table-column {
    background-color: #691a16 !important;
    color: #fff !important;
  }
  .theme7 tr > th {
    background-color: #691a16 !important;
    color: #fff !important;
  }
  .theme8 th.table-column {
    background-color: #a62e21 !important;
    color: #fff !important;
  }
  .theme8 tr > th {
    background-color: #a62e21 !important;
    color: #fff !important;
  }
  .theme9 th.table-column {
    background-color: #2e2e2e !important;
    color: #fff !important;
  }
  .theme9 tr > th {
    background-color: #2e2e2e !important;
    color: #fff !important;
  }
  /*themeing ends */
    
  .small { font: italic 13px sans-serif; }
  .heavy { font: bold 30px sans-serif; }

.blink_text {
  animation-name: blinker;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  }
  
  .rotate_ON {
  animation: rotation 1s infinite linear;
  transform-box: fill-box;
  transform-origin: center;
  }
  .rotate_OFF{
    /* transform-origin: center; */
    color: #fff ;
  }
  @keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
  }
  
  @keyframes blinker {  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
  }
    /* Note that the color of the text is set with the    *
     * fill property, the color property is for HTML only */
    .Rrrrr { font: italic 40px serif; fill: red; }
    @keyframes move {
      0% {
        left: 0;
      }
      
      100% {
        left: 90%
      }
    }
    .move {
      animation-name: move;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-direction: normal;
    }
    @keyframes flash {
      0%   { opacity: 1; }
      100% { opacity: 0; }
    }
    
    .arrow_ON {
      animation: flash 2.5s infinite;
    }
    .arrow_OFF{
      display: none;
    }
    @keyframes move {
      0% {
        left: 0;
      }
      
      100% {
        left: 90%
      }
    }
    @keyframes left {
      0% {
        left: 90%
      }
      
      100% {
        left: 0%
      }
    }
    @keyframes top {
      0% {
        top: 90%;
      }
      
      100% {
        top: 0%
      }
    }
    @keyframes bottom {
      0% {
        top: 0%;
      }
      
      100% {
        top: 90%
      }
    }
    /* .circle {
      position: relative;
      /* width: 4px;
      height: 2px; */
      /* border-radius: 50px ; 
  
    } */
    /* .circle1 {
      position: relative;
      width: 4px;
      height: 7px;
      /* border-radius: 50px ; 
  
    } */
    .sideLR_ON {
      animation-name: move;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
    .topTB_ON  {
      animation-name: top;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
    .sideRL_ON {
      animation-name: left;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
    .topBT_ON  {
      animation-name: bottom;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
    .sideLR_OFF{
      display: none;
    }
    .topTB_OFF{
      display: none;
    }
    .sideRL_OFF{
      display: none;
    }
    .topBT_OFF{
      display: none;
    }
    .normal {
      background-color: #bebebe;
      animation-direction: normal;
      opacity: 0.4;
      position: relative;
      /* background-image: url(./images/arrow-right.png); */
    }
    .reverse {
      background-color: #7f7fff;
      animation-direction: reverse;
    }
    .alternate {
      background-color: #7fffff;
      animation-direction: alternate;
    }
    .alternateReverse {
      background-color: #7fff7f;
      animation-direction: alternate-reverse;
    }
    p {
      margin: 0;
      font-size: 24px;
    }
    .blink {
      animation-name: blinker;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }   
    @keyframes blinker {  
     0% { opacity: 1.0; }
     50% { opacity: 0.0; }
     100% { opacity: 1.0; }
    }
  