.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  display: none !important;
}

li.ant-time-picker-panel-select-option-disabled {
  display: none !important;
}

.ant-descriptions-bordered .ant-descriptions-row {
  border: 1px solid #8c8c8c !important;
}

.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  border-right: 1px solid #8c8c8c !important;
}

.tableView .ant-table-body {
  border: 1px solid white !important;
}

.Open {
  background: #fffb8f !important;
}

.Approved {
  background: #bae637 !important;
}

.Waiting {
  background: #ffc069 !important;
}

.Rejected {
  background: #ff4d4f !important;
}

.Assigned {
  background: #87e8de !important;
}

.card-container {
  border: none;
}

.card-container>.ant-tabs-card>.ant-tabs-content {
  margin-top: -16px;
}

.card-container>.ant-tabs-card>.ant-tabs-content>.ant-tabs-tabpane {
  background: #193652;
  padding: 16px;
}

.card-container>.ant-tabs-card>.ant-tabs-bar {
  border-color: #193652;
}

.card-container>.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab {
  border: 1px solid transparent;
  background: transparent;
}

.cardContainer>.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab-active {
  background: #496d91;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}

.cardContainer {
  border: none;
  width: 100%;
}

.cardContainer .ant-form-item-label>label {
  color: #fff !important;
  font-weight: bold !important;
}

.cardContainer>.ant-tabs-card>.ant-tabs-content {
  margin-top: -16px;
}

.cardContainer>.ant-tabs-card>.ant-tabs-content>.ant-tabs-tabpane {
  background: #496d91;
  padding: 16px;
}

.cardContainer>.ant-tabs-card>.ant-tabs-bar {
  border-color: #496d91;
}

.cardContainer>.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab {
  border: 1px solid transparent;
  background: transparent;
}

.cardContainer>.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab-active {
  background: #496d91;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}

.headerTitle {
  font-weight: bold;
  color: steelblue;
  font-size: 18px;
}

.subHeaderTitle {
  color: #8c8c8c;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 40px;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-ink-bar {
  visibility: hidden;
}

.elogTab .ant-tabs-tab {
  height: 40px;
  margin: 0;
  margin-right: 2px;
  padding: 0 16px;
  line-height: 38px;
  background: #fafafa;
  border: 1px solid #fff !important;
  border-radius: 4px 4px 0 0;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.elogTab .ant-tabs-tab-active {
  height: 40px;
  color: white !important;
  background: #193652;
  border-color: #193652;
  font-weight: bold !important;
  border-bottom: 1px solid #193652;
}

.elogTab .ant-tabs-ink-bar {
  background-color: #193652 !important;
}

.elogTab1 .ant-tabs-tab-active {
  height: 40px;
  color: white !important;
  background: #006d75;
  border-color: #fff;
  font-weight: bold !important;
  border-bottom: 1px solid #fff;
}

.elogTab1 .ant-tabs-tab:hover {
  color: #fff;
}

.elogTab1 .ant-tabs-ink-bar {
  background-color: #fff !important;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active::before {
  border-top: 2px solid transparent;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-disabled {
  color: #1890ff;
}

.batchUpdateForm .ant-form-item-label>label {
  color: #fff;
  font-weight: bold;
}

.formCustomLabel .ant-form-item-label>label {
  color: #fff;
  font-weight: bold;
}

.batchHeader .ant-descriptions-item-label {
  background-color: #193652 !important;
  color: #fff !important;
  font-weight: bold;
  font-size: 16px;
}

.remarksHeading {
  color: white !important;
  font-weight: bold;

}

.remarksText {
  color: black !important;
  font-weight: bold;
}

.saveButton {
  background: #193652 !important;
  color: #fafafa !important;
  font-weight: bold !important;
}

.saveBatch {
  background: #36cfc9 !important;
  color: rgb(255, 255, 255) !important;
  font-weight: bold !important;
}

.deleteButton {
  background: #ff7875 !important;
  color: white !important;
  font-weight: bold !important;
  border-color: #ff7875 !important;
}

.batchHeader .ant-descriptions-item-content {
  color: #fff;
}

.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #fff !important;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  border: 1px solid #fff !important;
}

.ant-descriptions-bordered .ant-descriptions-item-content {
  border: 1px solid #fff !important;
}

.customCollapse .ant-collapse-header {
  background-color: #285c8e !important;
  color: white !important;
}

.customCollapse .ant-collapse-content-box {
  background-color: #214972;
  color: #fff !important;
}

/*ElogadhocView*/
.adhocView {
  background: #1a3652 !important;
  padding: 40px;
}

/*ElogbookView*/
.elogbookView {
  background: #d9d9d9;
}

/*CardViewLog*/
.gridStyle {
  width: 25%;

  border: 2px solid #193652 !important;
  border-radius: 15px;
}

/*elogBookUser*/
.elogBookUser {
  background: #193652 !important;
}

/*EquipmentBodyCodeForm Start*/
.subSectionTitle {
  background-color: #285c8e !important;
  color: #fff;
  /* padding: 5px 15px; */
  /* border-radius: 5px; */
  font-weight: bold;
  /* margin-top: 5px; */

}

.subSecBody {
  border: 2px solid white !important;
}

.subSectionBody {

  border: 1px solid grey !important;
  background: #193652;
  margin: 10px 10px;
  padding: 10px;
  border-radius: 8px;
}

.mainHeader {
  margin-top: 30px;
  margin-bottom: 30px;
  color: black;
}

.formRowWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.inputItem {
  width: 300;
  font-weight: bold;
}

.subSectionFormBody {
  padding-right: 2em;
  padding: 5px 20px;

  /* margin: 20px 0; */
}

.subCollapseFormbody {
  margin: 20px 10px;

}

/*EquipmentBodyCodeForm End*/
/*gasLogForm start */
/* .gasLogForm {
  margin: 20px 0px;
} */

/* .TableView tr > th{
  background-color: #3273b8 !important;
  color: #fff !important;
  font-weight: bold !important;
} */
.iconStyle {
  color: #193652 !important;
}

/*gasLogForm end */
.AdhocCard .ant-card-body {
  min-height: 101px !important;
}

.bodyForm .ant-form-item .ant-form-item-label {

  display: flex !important;
}
.ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
  color: white;
}
.bodyCodeForm {
  margin: 10px 20px;
  background: #214972 !important;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  border-radius: 5px;
  /* border: 1px solid #193652; */
}

.cardTopBorder .ant-card-body {
  padding: 14px !important;
}

/* .ant-advanced-search-form { */
/* padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px; */
/* } */

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 3;
}

/* .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #2b77b7;
  color: #fff !important;
}
.ant-tree li .ant-tree-node-content-wrapper{
  color: #fff;
} */
.fftBackground {
  background: rgb(194, 223, 255) !important;
  padding: 40px;
  color: white !important;
}

.fftBackground .ant-form-item-label>label {
  color: black;
  font-weight: bold;
}

/* 
.ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  color: white !important;
  background: #193652;
} */

/*Form with form subsection form table - table  */

.customTable tr {
  background-color: #011931 !important;
  color: black !important;
  text-align: center !important;
}

.customTable th {
  color: white !important;
  font-weight: bold !important;
  text-align: center !important;
}

.customTable th {
  background-color: #285c8e !important;
  font-weight: bold !important;
}

.customTable tbody tr:hover td {
  background-color: #dedede !important;
  cursor: pointer;
  color: black !important;
}

.customTable tr:nth-child(even) {
  background: #7a8793 !important;
}

.customTable tr:nth-child(odd) {
  background: #bdc0c2 !important;
}

/*adhocCardview*/
.AdhocCard {
  margin-top: 20px;
  background: #193652;
}

.cardTopBorder {
  background: white;
  border: 1px solid #bfbfbf;
  border-top: 6px solid steelblue;
  margin: 2px;
}

.cardView {
  border-radius: 15px 15px 15px;
}

.newLink {
  color: #9e1068;
  font-weight: bold;
}

.runningLink {
  color: red;
  font-weight: bold;
}

.completeLink {
  color: green;
  font-weight: bold;
}

.treeForFormWithMultiSubHeaderTable {
  color: white !important;
}
