.formComponent{
    background: rgb(215, 231, 240);
    padding: 30px;
    border-radius:6px;
}
.formComponent .ant-alert.ant-alert-no-icon {
    padding: 2px 17px;
    width: 32%;
    margin-left: 193px;
    margin-top: 13px;
  
  }
  .mainForm .ant-form-item {
    margin-bottom: 0px;
  }
  .mainForm .ant-form-item-label{
      font-weight: bold;
    
  }
  .mainForm .ant-form-item-label > label{
    color:#616264 !important;
    font-weight: bold;
  }
  .mainForm .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active{
    background: #196f9f;
   
  }
  .mainForm .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color:#fff;
  }
  .mainForm .ant-table-thead > tr > th {
   
    color: rgb(215, 231, 240);
    font-weight: bold;
    background: #196f9f;
   
  }