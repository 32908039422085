.start-node, .end-node {
    height: 64px;
    width: 64px;
    border-radius: 50%;
    line-height: 64px;
    color: #fff;
    text-align: center;
    }
    
    .start-node {
    background-color: #338aff;
    }
    
    .end-node {
    background-color: #666;
    }
    
    .other-node, .condition-node {
    width: 224px;
    border-radius: 4px;
    color: #666;
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
    }
    
    .other-node {
    height: 118px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    }
    
    .condition-node {
    height: 44px;
    padding: 12px 16px;
    }
    .flowchart-svg{
    background-color: #386ca0 !important;
    }
    
    /* .flowchart-toolbar {
    /* width: 100% !important;
    height: 6% !important; */
    /* border-left: 1px solid #dfdfdf !important;
    border-top: 1px solid #dfdfdf !important;
    border-bottom: 1px solid #dfdfdf !important;
    position: fixed;
    display: inline-flex; 
    } */
    .workForm {
        background: #1a3652 !important;
        padding: 8px;
        color: #fff !important;
      }
      .WorkFlowDrawer .ant-drawer-body{
        background: #fff !important;
      }
      .WorkFlowDrawer .ant-drawer-header{
        background-color: #fafbfc!important;
       
      }
      .WorkFlowDrawer .ant-drawer-title{
        color:#484848 !important;
      }
      .WorkFlowDrawer  .ant-drawer-close{
        color:#484848 !important;
      }
      .workForm .ant-form-item-label > label{
        color:#fff !important;

      }
      .bg-transparent{
        display:none !important;
      }