.steps-content {
    margin-top: 0px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    padding: 80px 30px;
  }
  
  .steps-action {
    margin-top: 24px;
  }
  .onOkCancelDevice .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    display: none;
    /* height:"100px" */
  }
  .onOkCancelDevice .ant-modal-body {
    padding: 24px;
    height:300px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
  }
  .onOkCancel .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    display: none;
    height:"400px"
  }
  .onOkCancel .ant-modal-body {
    padding: 24px;
    height:200px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
  }
  .button {
    display: inline-block;
    margin-top: 40px; 
    /* psadding: 2px 8px; */
}