

.TableWidget tbody tr:hover td {
  background-color: #504f4f !important;
  cursor: pointer;
}

.TableWidget tr {
  background-color: #252627 !important;
  text-align: center !important;
 
  color:white;
}

.TableWidget th {
  background-color: #000000 !important; 
  color: white !important;
  text-align: center !important;
  /* border: 1px solid #636161; */
  font-weight: bold;
  font-size: 1.1em;
}

.TableWidget tbody tr:hover td {
  background-color: #504f4f !important;
  cursor: pointer;
}
.TableWidget .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  padding: 16px 16px;
  overflow-wrap: break-word;
}

.TableWidget tbody td {
  /* border-left: .5px solid gray !important;
  border-right: .5px solid gray !important; */
  /* border-bottom: 1px solid #8c8c8c !important; */
  background-color: black !important;
  font-size: 13px;
  /* border: 1px solid #868686; */
  color: "white";
}

.TableWidget .ant-table-placeholder {
  background-color: #595959 !important;
}

.TableWidget ant-table-thead {
  background-color: grey !important;
  cursor: pointer;
}
 td.ant-table-column-sort{
background-color: #fafafa00 !important;
}

.TableLightWidget tr {
  background-color: #0d1115 !important;
  text-align: center !important;
  font-size: 1.1em;
}
.TableLightWidget th {
  color: white !important;
  text-align: center !important;
}

.TableLightWidget th {
  background-color: rgba(85, 84, 84, 0.65) !important;  
  /* border-bottom: none !important; */
  font-size: 1.1em;
  font-weight: 800;
}

.TableLightWidget tbody tr:hover td {
  /* background-color: #06050561 !important; */
  cursor: pointer;
}

.TableLightWidget tbody td {
  /* border-left: .5px solid gray !important;
  border-right: .5px solid gray !important; */
  /* border-bottom: none !important; */
  font-size: 1.1em;
}

.TableLightWidget ant-table-thead {
  background-color: grey !important;
  cursor: pointer;
}

.TableLightWidget .ant-table-placeholder{
  background-color: #595959 !important;
}
.TableText{
 width: 100px;
 /* 
 Note: The Below 4 Lines are necessary for ellipsis to work.
 */
 display: block;/* Change it as per your requirement. */
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}

.barTable tr {
  background-color: #0d1115 !important;
  text-align: center !important;
  font-size: 1.1em;
}
.barTable th {
  color: white !important;
  text-align: center !important;
}

.barTable th {
  background-color: rgba(85, 84, 84, 0.65) !important;
  border-bottom: none !important;
  font-size: 1.1em;
  font-weight: 800;
}

.barTable tbody tr:hover td {
  background-color: #d9d9d9 !important;
  cursor: pointer;
}

.barTable tbody tr td {
  /* border-left: .5px solid gray !important;
  border-right: .5px solid gray !important; */
 background-color: #f5f5f5 !important;
  font-size: 1.1em;
}



.notificationMain{
  width: 251px;
 display: inline-block;
  height: 61px;
  margin-bottom: 20px;
  margin-right:2%;
  margin-left:2%;
  background:grey;
  border-radius: 10px;
  position: relative;
}
.notificationDiv{
  margin-top:5px;
  background-color: #d9d9d9;
  border-radius: 10px;
  width:100px;
  float:left;
  text-align: center;
  height:80%;
  margin:2%;

}

.notificationDiv1{
  margin-top:5px;
  background-color: #d9d9d9;
  border-radius: 10px;
  width:100px;
  
  text-align: center;
  height:80%;
  margin-left:50%;
  margin-top:2%;
  
  
}
.notificationDiv span{
  font-size: 2.5em;
}
.notificationDiv1 span{
  font-size: 2.5em;
}

.basictable th {
  color: white !important;
  font-weight: bold !important;
  text-align: center !important;
}

.basictable th {
  background-color: #214972 !important;
  font-weight: bold !important;
}

.basictable tbody tr:hover td {
  background-color: #dedede !important;
  cursor: pointer;
  color: black !important;
}

.basictable tr:nth-child(even) {
  background: #7a8793 !important;
}

.basictable tr:nth-child(odd) {
  background: #bdc0c2 !important;
}
.linkList{
  position: absolute;
  margin-top: 12px;
}

.TableWidgetForGraph tr {
  background-color: #0d1115 !important;
  text-align: center !important;
  font-size: 1.2em;
  font-weight: bold;
}
.TableWidgetForGraph th {
  color: #ccc6c6 !important;
  text-align: center !important;
}

.TableWidgetForGraph th {
  background-color: rgba(85, 84, 84, 0.65) !important;  
  /* border-bottom: none !important; */
  font-size: 1.1em;
  font-weight: 800;
}

.TableWidgetForGraph tbody tr:hover td {
  background-color: #ddd !important;
  cursor: pointer;
}

.TableWidgetForGraph tbody td {
  /* border-left: .5px solid gray !important;
  border-right: .5px solid gray !important; */
  /* border-bottom: 1px solid #8c8c8c !important; */
  background-color: #ccc6c6 !important;
  font-size: 13px;
}

.TableWidgetForGraph ant-table-thead {
  background-color: grey !important;
  cursor: pointer;
}
